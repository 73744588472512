<template>
    <!-- 表单管理 -->
    <div class="formment-box">
        <div class="box">
            <div class="top-nav">
                <div class="title">角色管理</div>
                <div>
                    <el-button type="primary" size="medium" @click="goAdd">
                        <i class="el-icon-plus fontweight9"></i><span class="fontweight4">添加角色</span>
                    </el-button>
                </div>
            </div>

            <div class="tabble-box">
                <infoTable :tableHeadConfig="formMenttitle" :selection="false" align="left" :tableLoadData="tablelist">
                    <template v-slot:sort="slotData">
                        <div>{{ slotData.data.row.sort }} <img src="@/assets/images/icon/modify-pen.png" /></div>
                        <!-- <el-switch v-model="slotData.data.row.sort"></el-switch> -->
                    </template>

                    <template v-slot:operation="slotData">
                        <div class="oprtion-style">
                            <div class="oper-modify" @click="goEdit(slotData.data.row)">编辑</div>
                            <div class="border-box"></div>
                            <div class="oper-del" @click="delInfo(slotData.data.row)">删除</div>
                        </div>
                    </template>
                </infoTable>
            </div>
            <div class="bottom-pagination">
                <infoPage :pagination="pagination" @pageclcik="pageclcik"></infoPage>
            </div>
        </div>
        <deldialog :appId="roleId" title="是否确定要删除该角色?" @dialogChange="delChange" :dialogVal="delDialog"></deldialog>
    </div>
</template>

<script>
import { sessionGetKey } from "@/utils/sessionStorage.js";
export default {
    data() {
        return {
            roleId: "",
            delDialog: false,
            platform_id: "",
            pagination: {
                currentpage: 1,
                total: 1,
                totalpage: 1,
                pageSize: 10,
            },
            tablelist: [],
            formMenttitle: [
                {
                    label: "ID",
                    field: "id",
                    sort: true,
                },
                {
                    label: "角色名称",
                    field: "name",
                },
                {
                    label: "创建时间",
                    field: "created_at",
                },
                {
                    label: "操作",
                    field: "operation",
                    columnType: "slot",
                    slotName: "operation",
                },
            ],
        };
    },
    mounted() {
        this.platform_id = sessionGetKey("platformId");
        this.getRole();
    },
    methods: {
        goAdd() {
            this.$router.push({ path: "/systemset/rolement/addrole", query: { type: "1" } });
        },
        goEdit(row) {
            this.$router.push({ path: "/systemset/rolement/addrole", query: { type: "2", data: JSON.stringify(row) } });
        },
        delInfo(row) {
            this.roleId = row.id;
            this.delDialog = true;
        },
        delChange(type, id) {
            const that = this;
            that.delDialog = false;
            if (type == 1) {
                that.$request({
                    url: that.$api.role.delRole,
                    method: "post",
                    data: { id: id },
                    params: {
                        platform_id: that.platform_id,
                    },
                }).then((res) => {
                    that.getRole();
                });
            }
        },
        pageclcik(e) {
            this.pagination.currentpage = e;
            this.getRole();
        },
        getRole() {
            const that = this;
            let { currentpage, total, totalpage, pageSize } = that.pagination;
            that.$request({
                url: that.$api.role.getRole,
                method: "post",
                data: {
                    page: currentpage,
                },
                params: {
                    platform_id: that.platform_id,
                },
            }).then((res) => {
                total = res.data.length;
                totalpage = Math.ceil(res.data.length / pageSize);
                that.tablelist = res.data;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.formpopup {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    background-color: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}
.fontweight9 {
    font-weight: 900;
}
.fontweight4 {
    font-weight: 400;
    margin-left: 3px !important;
}
.formment-box {
    height: 100%;
    padding: 66px;
    min-height: 600px;
    .border-box {
        width: 1px;
        height: 18px;
        background: #4458fe;
        margin: 0 10px;
    }
    /deep/.el-input__inner {
        width: 300px;
    }
    .oprtion-style {
        display: flex;
        align-items: center;
    }
    .oper-modify {
        color: #4458fe;
    }
    .oper-del {
        color: #4458fe;
    }
    .box {
        border-radius: 16px;
        img {
            margin-left: 10px;
            width: 22px;
            height: 22px;
        }
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        background: #fff;
        padding: 33px;
        .top-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
                padding-bottom: 10px;
                color: #4458fe;
                font-size: 20px;
                font-weight: 600;
                border-bottom: #4458fe 2px solid;
            }
        }
        .tabble-box {
            margin: 21px 0;
        }
        .search-box {
            margin: 21px 0;
            display: flex;
            align-items: center;
            .search-input {
                margin-right: 20px;
                padding: 0 15px;
                border-radius: 6px;
                width: 500px;
                box-sizing: border-box;
                border: 1px solid #999;
                display: flex;
                align-items: center;
                /deep/.el-input__inner {
                    border: 0 !important;
                    padding: 0 !important;
                }
                .el-icon-search {
                    font-size: 20px;
                    color: #999;
                }
            }
            .el-button {
                border: #4458fe 1px solid;
                background-color: #4458fe !important;
                color: #ffffff;
            }
        }
    }
    .bottom-pagination {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 30px;
    }
}
</style>
